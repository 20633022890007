import { Bio } from '../../pages/home/components/bio'
import './style.css';



export const AboutMe = () => {


    return (
        <>
              <Bio></Bio>
        </>
    );
};

export default AboutMe;