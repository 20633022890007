import './style.css';

const header = 'The Waweezy Project';

export const Header = () => {
    return (
     <h1 className='header'>{header}</h1>
    );
  }
  
  export default Header;